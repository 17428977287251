:root {
    --red:  rgb(179, 48, 48); 
    --green:  #27ae60; 
    --text--light: rgb(230, 228, 228);
    --text--dark:rgb(61, 61, 61);
    --nav-bg:linear-gradient(315deg, #0000006e 0%, #1d1d1d41 74%);
    --footer-bg:linear-gradient(315deg, rgb(179, 48, 48) 0%, rgb(136, 30, 30) 74%);
    --shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    --bg--dark: linear-gradient(315deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.753) 85%);
    --bg--dark--2:linear-gradient(315deg, rgba(46, 45, 45, 0.85) 0%, rgba(14, 13, 13, 0.842) 85%);
    --bg--lighter: linear-gradient(315deg, rgba(0, 0, 0, 0.623) 0%, rgba(0, 0, 0, 0.658) 85%);
    --overlay-for-dark:linear-gradient(
        315deg,
        rgba(255, 255, 255, 0.171) 0%,
        rgba(255, 255, 255, 0.027) 75%
    );
}

body {
    margin:0;
}

a {
    text-decoration: none;
    font-style:inherit !important;
    font-family:inherit !important;
    color:inherit;
}
